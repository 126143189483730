import ModelValidator from '../model.validator';
import { ClientEditDto } from '../../../model/dto/client';
import { OrganizationTypeEnum } from '../../../model';
import validator from 'validator';
import isEmail = validator.isEmail;
import isNumeric = validator.isNumeric;

class Validator extends ModelValidator<ClientEditDto> {
  validate(model: ClientEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.user, errors);
    this.checkLength(model.index, errors, 6, 'main');
    if (model.index) {
      this.check(model.index, errors, isNumeric);
    }
    this.checkLength(model.district, errors, 255);
    this.checkLength(model.locality, errors, 255);
    this.checkLength(model.street, errors, 255);
    this.checkLength(model.house, errors, 255);
    this.checkLength(model.building, errors, 255);
    this.checkLength(model.room, errors, 255);
    this.checkLength(model.additionalInfo, errors, 255);
    this.checkLength(model.fullName, errors, 512);
    this.checkPhysical(model, errors);
    this.checkIndividual(model, errors);
    this.checkJuridical(model, errors);
    return errors;
  }

  private checkPhysical(model: ClientEditDto, errors: string[]) {
    if (model.type !== OrganizationTypeEnum.PHYSICAL) return;
    this.checkLength(model.inn, errors, 12);
  }

  private checkIndividual(model: ClientEditDto, errors: string[]) {
    if (model.type !== OrganizationTypeEnum.INDIVIDUAL) return;

    this.checkRequired(model.inn, errors);
    this.checkRequired(model.ogrnip, errors);
    this.checkLength(model.phone, errors, 255);
    this.checkRequired(model.email, errors);
    this.check(model.email, errors, isEmail);
  }

  private checkJuridical(model: ClientEditDto, errors: string[]) {
    if (model.type !== OrganizationTypeEnum.JURIDICAL) return;

    this.checkRequired(model.inn, errors);
    this.checkRequired(model.ogrn, errors);
    this.checkRequired(model.kpp, errors);
    this.checkRequired(model.shortName, errors);
    this.checkLength(model.shortName, errors, 255);
    this.checkRequired(model.fullName, errors);
    this.checkLength(model.phone, errors, 255);
    this.checkRequired(model.email, errors);
    this.check(model.email, errors, isEmail);
  }
}

export const EditClientValidator = new Validator();

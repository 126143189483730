import { Route } from 'react-router-dom';
import { ACCOUNT_ROUTES } from '../../route';
import { ConfirmUserSettings } from '../ConfirmUserSettings';
import React from 'react';
import { ConfirmEmail } from '../ConfirmEmail';

export const ACCOUNT_ROUTE = (
    <Route>
        <Route path={ACCOUNT_ROUTES.confirmUserSettings} element={<ConfirmUserSettings />} />
        <Route path={ACCOUNT_ROUTES.confirmEmail} element={<ConfirmEmail />} />
    </Route>
)